<template>
  <ComponentPricing :pricingPlanNameProp="pricingPlanName" :pricingPlanDescriptionProp="pricingPlanLegend" :subscriptionButtonNameProp="subscriptionButtonName" :pricingPlanPriceProp="pricingPlanPrice" :stripeProductNamesProp="stripeProductNames" :pricingIconProp="pricingIcon" :successEndpointProp="successEndpoint" :cancelEndpointProp="cancelEndpoint" />
</template>

<script>
export default {
  name: 'SubscribeTeamSection',
  components: {
    ComponentPricing: () => import('./ComponentPricing.vue')
  },
  data: () => ({
    pricingPlanName: ['Team plan'],
    pricingPlanLegend: ['Monthly Subscription'],
    pricingPlanPrice: [499],
    stripeProductNames: ['team'],
    pricingIcon: 'mdi-account-supervisor-circle',
    subscriptionButtonName: 'Team Subscription',
    successEndpoint: '/download?sessionId={CHECKOUT_SESSION_ID}',
    cancelEndpoint: '/subscribeteam'
  })
}
</script>
